<template>
  <div class="modal-card" style="min-width: 1000px; border-radius: 10px">
    <section class="modal-card-body">
      <h1 class="title">
        {{ $tf("resource.employeeSelect.title|Alkalmazott kiválasztása") }}
      </h1>
      <div class="is-flex has-gap-2 mb-4 is-align-items-center">
        <div class="is-flex is-flex-direction-column">
          <span class="uppercase-subtitle-small has-text-grey">
            {{ $tf("resource.employeeSelect.filters.name|Szűrés névre") }}
          </span>
          <b-input v-model="employeeNameFilter" icon-right="search" />
        </div>

        <div class="is-flex is-flex-direction-column">
          <span class="uppercase-subtitle-small has-text-grey">
            {{ $tf("resource.employeeSelect.filters.role|Szűrés munkakörre") }}
          </span>

          <b-select
            v-model="roleFilter"
            expanded
            :placeholder="
              $tf('resource.employeeSelect.filters.role|Szűrés munkakörre')
            "
          >
            <option :value="null">
              {{ $tf("resource.employeeSelect.filters.all|Összes") }}
            </option>
            <option v-for="option in roles" :value="option" :key="option">
              {{ option }}
            </option>
          </b-select>
        </div>

        <div class="is-flex is-flex-direction-column">
          <span class="uppercase-subtitle-small has-text-grey">
            {{
              $tf(
                "resource.employeeSelect.filters.seniority|Szűrés szenioritásra"
              )
            }}
          </span>

          <b-select v-model="seniorityFilter" expanded>
            <option :value="null">
              {{ $tf("resource.employeeSelect.filters.all|Összes") }}
            </option>
            <option
              class="w-100"
              v-for="option in seniorities"
              :value="option"
              :key="option"
            >
              {{ option }}
            </option>
          </b-select>
        </div>

        <div class="mt-5">
          <b-checkbox v-model="myTeamOnlyFilter">
            {{
              $tf(
                "resource.employeeSelect.filters.ownTeamOnly|Csak a saját csapat megjelenítése"
              )
            }}
          </b-checkbox>
        </div>
      </div>

      <b-table
        :data="filtered"
        :row-class="(row) => row === selectedRow && 'selected-row'"
      >
        <b-table-column
          :label="$tf('resource.employeeSelect.table.label.name|Név')"
          field="name"
          v-slot="props"
        >
          <div
            class="is-flex is-align-items-center cursor-pointer"
            @click="selectedRow = props.row"
          >
            <figure
              class="image is-32x32 ml-0 mt-0 mb-0 mr-3"
              style="flex: 0 0 32px; max-width: 32px"
            >
              <img
                :src="
                  props.row.avatar
                    ? props.row.avatar
                    : 'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
                "
                alt="Avatar"
                class="is-rounded"
              />
            </figure>
            <span class="has-font-weight-700">
              {{ props.row.name }}
            </span>
          </div>
        </b-table-column>
        <b-table-column
          :label="$tf('resource.employeeSelect.table.label.role|Munkakör')"
          field="role"
          v-slot="props"
        >
          <div class="is-flex is-flex-wrap-wrap has-gap-2">
            <indicator-tag :indicator-id="props.row.roleId" />
          </div>
        </b-table-column>
        <b-table-column
          :label="
            $tf('resource.employeeSelect.table.label.seniority|Szenioritás')
          "
          v-slot="props"
        >
          <indicator-tag :indicator-id="props.row.seniorityId" />
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">
            {{ $tf("resource.employeeSelect.table.empty|Nincs találat") }}
          </div>
        </template>
      </b-table>

      <div class="mt-4">
        <b-button
          type="is-primary"
          :disabled="!selectedRow"
          @click="addEmployee"
        >
          {{ $tf("resource.employeeSelect.button.addEmployee|Hozzáadás") }}
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import IndicatorTag from "@/components/module/info/IndicatorTag.vue";

export default defineComponent({
  name: "EmployeeSelect",
  components: { IndicatorTag },
  props: {
    employees: {
      type: Array,
      required: true,
    },
    leadTeams: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      employeeNameFilter: "",
      roleFilter: null,
      seniorityFilter: null,
      myTeamOnlyFilter: null,
      filtered: [],
      selectedRow: null,
    };
  },
  async mounted() {
    this.filtered = this.employees;
  },
  watch: {
    employeeNameFilter() {
      this.doFilter();
    },
    roleFilter() {
      this.doFilter();
    },
    seniorityFilter() {
      this.doFilter();
    },
    myTeamOnlyFilter() {
      this.doFilter();
    },
  },
  computed: {
    roles() {
      return this.employees
        .map((emp) => emp.role)
        .filter((role, index, roles) => roles.indexOf(role) === index);
    },
    seniorities() {
      return [...new Set(this.employees.map((emp) => emp.seniority))];
    },
  },
  methods: {
    doFilter() {
      const text = this.employeeNameFilter.toLowerCase().trim();
      if (text) {
        this.filtered = this.employees.filter((employee) =>
          employee.name?.toLowerCase().includes(text)
        );
      } else {
        this.filtered = this.employees;
      }

      if (this.roleFilter) {
        this.filtered = this.filtered.filter(
          (employee) => employee.role === this.roleFilter
        );
      }
      if (this.seniorityFilter) {
        this.filtered = this.filtered.filter(
          (employee) => employee.seniority === this.seniorityFilter
        );
      }

      if (this.myTeamOnlyFilter) {
        const myEmployees = this.leadTeams.flatMap(
          (team) => team.employees?.map((employee) => employee.employeeId) ?? []
        );
        this.filtered = this.filtered.filter((employee) =>
          myEmployees.includes(employee.id)
        );
      }

      if (!this.filtered.includes(this.selectedRow)) {
        this.selectedRow = null;
      }
    },
    addEmployee() {
      this.$emit("add-employee", this.selectedRow.id);
    },
  },
});
</script>

<style>
tr.selected-row {
  background-color: #bfd9bf;
}
</style>

<style scoped lang="scss">
.is-rounded-5 {
  border-radius: 5px;
}
</style>
