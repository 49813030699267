<template>
  <section v-if="!loading">
    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('resource.utilization.filter.team|Csapat kiválasztása')"
        >
          <b-select expanded v-model="filters.team">
            <option
              v-for="team in teams"
              :value="team.identifier"
              v-bind:key="team.identifier"
            >
              {{ team.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field
          :label="$tf('resource.utilization.filter.employee|Szűrés névre')"
        >
          <b-autocomplete
            rounded
            v-model="filters.employee"
            :data="filteredEmployees"
            :placeholder="
              $tf('workSchedule.global.search.placeholder|Munkatárs')
            "
            icon="search"
            clearable
            field="name"
            @select="(option) => (this.filters.selectedEmployee = option)"
          >
            <template #empty>{{
              $tf("workSchedule.global.search.empty|Nincs találat")
            }}</template>
          </b-autocomplete>
        </b-field>
      </div>
      <div class="column">
        <b-field :label="$tf('resource.utilization.filter.roles|Munkakör')">
          <multiselect-dropdown
            v-model="filters.roles"
            :items="roles"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
            use-tf
          />
        </b-field>
      </div>
      <div class="column">
        <b-field
          :label="$tf('resource.utilization.filter.seniority|Szenioritás')"
        >
          <multiselect-dropdown
            v-model="filters.seniorities"
            :items="seniorities"
            append-to-body
            has-select-all-option
            identifier-field="name"
            name-field="name"
            use-tf
          />
        </b-field>
      </div>
      <div class="column">
        <b-field :label="$tf('resource.utilization.filter.period|Időszak')">
          <b-select expanded v-model="filters.period">
            <option value="custom">
              {{
                $tf(
                  "resource.utilization.filter.period.custom|Egyéni intervallum"
                )
              }}
            </option>
            <option value="previous_and_next">
              {{
                $tf(
                  "resource.utilization.filter.period.previousAndCurrentWeek|Előző és következő hét"
                )
              }}
            </option>
            <option value="next_week">
              {{
                $tf("resource.utilization.filter.period.nextWeek|Következő hét")
              }}
            </option>
            <option value="next_month">
              {{
                $tf(
                  "resource.utilization.filter.period.nextMonth|Következő hónap"
                )
              }}
            </option>
            <option value="next_2_months">
              {{
                $tf(
                  "resource.utilization.filter.period.nextTwoMonths|Következő 2 hónap"
                )
              }}
            </option>
            <option value="next_4_months">
              {{
                $tf(
                  "resource.utilization.filter.period.nextFourMonths|Következő 4 hónap"
                )
              }}
            </option>
            <option value="next_6_months">
              {{
                $tf(
                  "resource.utilization.filter.period.nextSixMonths|Következő 6 hónap"
                )
              }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field
          :label="
            $tf('resource.utilization.filter.period.fromDate|Kezdeti dátum')
          "
          v-if="filters.period === 'custom'"
        >
          <b-datepicker icon="calendar-alt" v-model="filters.fromDate" />
        </b-field>
      </div>
      <div class="column">
        <b-field
          :label="
            $tf('resource.utilization.filter.period.toDate|Befejezési dátum')
          "
          v-if="filters.period === 'custom'"
        >
          <b-datepicker icon="calendar-alt" v-model="filters.toDate" />
        </b-field>
      </div>
    </div>
    <div id="utilization-table-container" ref="tableWrapper">
      <b-table
        :data="extendedAssociations"
        class="table-height-hack"
        detailed
        v-if="filters.team && !teamDataLoading"
      >
        <b-table-column
          field="employee.name"
          :label="$tf('resource.utilization.table.employee|Név')"
          v-slot="prop"
        >
          <UtilizationEmployeeBrief :data="prop.row"></UtilizationEmployeeBrief>
        </b-table-column>
        <b-table-column
          field="employee.roles"
          :label="$tf('resource.utilization.table.roles|Munkakör')"
          v-slot="prop"
        >
          <indicator-tag :indicator-id="prop.row.employee.roleId" />
        </b-table-column>
        <b-table-column
          field="employee.seniority"
          :label="$tf('resource.utilization.table.seniority|Szenioritás')"
          v-slot="prop"
        >
          <indicator-tag :indicator-id="prop.row.employee.seniorityId" />
        </b-table-column>
        <b-table-column
          v-for="(week, i) in displayedWeeks"
          field="weekData.week"
          :key="i"
          :label="`${week.week}. ${$tf(
            'resource.utilization.table.weekUtilization|heti utilizáció'
          )}`"
          v-slot="prop"
        >
          <VerticalProgressBar
            :value="resolveAllocatedHours(prop, week.week)"
            :max-value="resolveExpectedHours(prop, week.week)"
            :info="(value, _) => `${convertHoursToMWD(value)} MWD`"
          ></VerticalProgressBar>
        </b-table-column>
        <template #detail="prop">
          <EmployeeUtilizationDetails
            :data="prop.row"
            :max-width="tableWidth"
          ></EmployeeUtilizationDetails>
        </template>
      </b-table>
      <div v-else>
        {{
          $tf(
            "resource.utilization.selectTeamForStart|Az adatok megjelenítéséhez kérlek válassz egy csapatot."
          )
        }}
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import debounce from "lodash.debounce";
import VerticalProgressBar from "@/components/VerticalProgressBar.vue";
import UtilizationEmployeeBrief from "@/components/ralloc/UtilizationEmployeeBrief.vue";
import EmployeeUtilizationDetails from "@/components/ralloc/EmployeeUtilizationDetails.vue";
import { convertHoursToMWD, formatDate } from "@/utils/util";
import IndicatorTag from "@/components/module/info/IndicatorTag.vue";

export default defineComponent({
  name: "UtilizationTeams",
  components: {
    IndicatorTag,
    EmployeeUtilizationDetails,
    UtilizationEmployeeBrief,
    VerticalProgressBar,
    MultiselectDropdown,
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  async mounted() {
    await this.fetchData();
    this.loading = false;
    this.$nextTick(() => {
      this.tableWidth = this.$refs.tableWrapper.clientWidth;
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  data() {
    return {
      loading: true,
      teamDataLoading: true,
      filters: {
        team: undefined,
        employee: undefined,
        selectedEmployee: undefined,
        roles: [],
        seniorities: [],
        period: "next_2_months",
        fromDate: undefined,
        toDate: undefined,
      },
      tableWidth: 0,
    };
  },
  computed: {
    roles() {
      return this.indicators.filter((indicator) => indicator.type === "ROLE");
    },
    seniorities() {
      return this.indicators.filter(
        (indicator) => indicator.type === "SENIORITY"
      );
    },
    filteredEmployees() {
      const employeeIds = [
        ...new Set(
          this.teams.flatMap((t) => t.employees).map((e) => e.employeeId)
        ),
      ];
      return this.employees.filter((employee) =>
        employeeIds.includes(employee.id)
      );
    },
    extendedAssociations() {
      return this.associations.map((association) => ({
        employee: this.getEmployee(association.employeeId),
        expectedHoursSum: this.getExpectedHoursSum(association),
        allocatedHoursSum: this.getAllocatedHoursSum(association),
        totalLoggedHours:
          this.worklogs(association.employeeId).totalSeconds / 3600,
        ...association,
      }));
    },
    displayedWeeks() {
      if (!this.associations || this.associations.length === 0) {
        return [];
      }
      const weekData = this.associations[0].weekData;
      return weekData
        .sort((a, b) => (a.year === b.year ? a.week - b.week : a.year - b.year))
        .slice(0, 7);
    },
    ...mapGetters({
      teams: "census_team/accessibleTeams",
      employees: "employee/workers",
      associations: "association/associationsByUsers",
      indicators: "user_indicator/indicators",
      worklogs: "worklog/worklogOfWeekForUser",
    }),
  },
  watch: {
    filters: {
      handler: debounce(async function () {
        await this.fetchAssociations();
      }, 500),
      deep: true,
    },
  },
  methods: {
    convertHoursToMWD,
    async fetchData() {
      await this.$store.dispatch("census_team/fetchAccessibleTeams");
      await this.$store.dispatch("employee/fetchWorkers");
      await this.fetchAssociations();
    },
    async fetchAssociations() {
      if (!this.filters.team) {
        return;
      }
      this.teamDataLoading = true;
      const [fromDate, toDate] = this.getPeriodDates();
      await this.$store.dispatch("association/getForUsers", {
        employees: this.getFilteredEmployeeIds(),
        from: fromDate,
        to: toDate,
      });

      const promises = this.getFilteredEmployeeIds().map((employee) =>
        this.$store.dispatch("worklog/fetchWeekForUser", {
          user: employee,
          from: formatDate(fromDate),
          to: formatDate(toDate),
        })
      );
      await Promise.all(promises);
      this.teamDataLoading = false;
    },
    getFilteredEmployeeIds() {
      let employeeIds = new Set();
      if (this.filters.selectedEmployee) {
        employeeIds.add(this.filters.selectedEmployee.id);
        return [...employeeIds];
      }
      if (this.filters.team) {
        this.teams
          .find((t) => t.identifier === this.filters.team)
          .employees.forEach((e) => employeeIds.add(e.employeeId));
      } else {
        this.teams
          .flatMap((t) => t.employees)
          .forEach((e) => employeeIds.add(e.employeeId));
      }

      let employeeIdArray = [...employeeIds];

      if (this.filters.roles.length > 0) {
        employeeIdArray = employeeIdArray.filter((id) => {
          const roleId = this.getEmployee(id).roleId;
          return this.filters.roles.some((role) => role === roleId);
        });
      }

      if (this.filters.seniorities.length > 0) {
        employeeIdArray = employeeIdArray.filter((id) =>
          this.filters.seniorities.includes(this.getEmployee(id).seniority)
        );
      }

      return employeeIdArray;
    },
    getEmployee(employeeId) {
      return this.filteredEmployees.find((e) => e.id === employeeId);
    },
    getPeriodDates() {
      let fromDate = new Date();
      let toDate = new Date();

      switch (this.filters.period) {
        case "previous_and_next":
          fromDate = fromDate.substractDays(7);
          toDate = toDate.addDays(7);
          break;
        case "next_week":
          toDate = toDate.addDays(14);
          break;
        case "next_month":
          toDate.setMonth(toDate.getMonth() + 1);
          break;
        case "next_2_months":
          toDate.setMonth(toDate.getMonth() + 2);
          break;
        case "next_4_months":
          toDate.setMonth(toDate.getMonth() + 4);
          break;
        case "next_6_months":
          toDate.setMonth(toDate.getMonth() + 6);
          break;
        case "custom":
          if (this.filters.toDate) {
            toDate = this.filters.toDate;
          }
          if (this.filters.fromDate) {
            fromDate = this.filters.fromDate;
          }
          break;
      }

      return [fromDate, toDate];
    },

    getExpectedHoursSum(association) {
      return association.weekData
        .map((a) => a.expectedHours)
        .reduce((a, b) => a + b, 0);
    },

    getAllocatedHoursSum(association) {
      return association.weekData
        .map((a) => a.allocatedHours)
        .reduce((a, b) => a + b, 0);
    },

    resolveExpectedHours(props, week) {
      return props.row.weekData.find((data) => data.week === week)
        ?.expectedHours;
    },

    resolveAllocatedHours(props, week) {
      return props.row.weekData.find((data) => data.week === week)
        ?.allocatedHours;
    },
    resizeHandler() {
      console.log(this.$refs.tableWrapper.clientWidth);
      this.tableWidth = this.$refs.tableWrapper.clientWidth;
    },
  },
});
</script>

<style>
.table-height-hack td {
  height: 1px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#utilization-table-container .detail-container {
  padding: 0;
}
</style>

<style scoped lang="scss"></style>
