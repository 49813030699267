<template>
  <div
    class="employee-details-container"
    :style="{ maxWidth: `calc(${this.maxWidth - 2}px - 1.5rem)` }"
    v-if="!loading"
  >
    <div class="basic-infos">
      <span
        >Munkaszerződés: <span class="bold">{{ workHours }} óra</span></span
      >
      <span
        >Csapatok: <span class="bold">{{ teamList }}</span></span
      >
      <span
        >Kompetenciák:
        <span class="bold">{{
          data.employee.competencies.map((c) => c.name).join(", ")
        }}</span></span
      >
    </div>
    <EmployeeProjectAllocations :data="data"></EmployeeProjectAllocations>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import EmployeeProjectAllocations from "@/components/ralloc/project-allocations/EmployeeProjectAllocations.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "EmployeeUtilizationDetails",
  components: { EmployeeProjectAllocations },
  props: {
    data: {
      type: Object,
      required: true,
    },
    maxWidth: {
      type: Number,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    await this.$store.dispatch(
      "work_schedule/getWorkSchedulesForUser",
      this.data.employeeId
    );
    this.loading = false;
  },
  computed: {
    workHours() {
      const workSchedule = this.workSchedules;
      return (
        workSchedule.mondayHours +
        workSchedule.tuesdayHours +
        workSchedule.wednesdayHours +
        workSchedule.thursdayHours +
        workSchedule.fridayHours +
        workSchedule.saturdayHours +
        workSchedule.sundayHours
      );
    },
    teamList() {
      return this.teams
        .filter((data) =>
          data.employees.map((e) => e.employeeId).includes(this.data.employeeId)
        )
        .map((data) => data.name)
        .join(", ");
    },
    ...mapGetters({
      workSchedules: "work_schedule/ownWorkSchedules",
      teams: "census_team/accessibleTeams",
    }),
  },
});
</script>

<style scoped lang="scss">
.basic-infos {
  display: flex;
  gap: 32px;
  margin-bottom: 12px;
  color: var(--custom-dark-blue);
}

.employee-details-container {
  padding: 1rem;
}

.bold {
  font-weight: 700;
}
</style>
