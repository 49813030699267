<template>
  <div class="card p-4 mb-2" @click="openLogModal(issue)">
    <div class="content">
      <div class="has-font-weight-700">
        <span> {{ issue.key }}: {{ issue.name }} </span>
        <b-tag type="is-primary" class="is-pulled-right">
          {{ issue.type }}
        </b-tag>
      </div>
      <div v-if="issue.assignee" class="my-3">
        {{ $tf("taskPage.issueCard.assignee|Hozzárendelt személy:") }}
        <user-info
          :user="employees.find((emp) => emp.id === issue.assignee)"
          display-mode="long"
          tooltip-position="top"
        ></user-info>
      </div>
      <div v-else class="my-1 has-text-grey">
        {{ $tf("taskPage.issueCard.noAssignee|Nincs hozzárendelt személy.") }}
      </div>
      <div v-if="issue.estimateSeconds" class="my-2">
        {{
          `${$tf(
            "taskPage.issueCard.estimateSeconds|Becslés:"
          )} ${secondsToJiraTime(issue.estimateSeconds)}`
        }}
      </div>
      <div v-else class="my-2 has-text-grey">
        {{ $tf("taskPage.issueCard.noEstimateSeconds|Nincs becslés.") }}
      </div>
      <div v-if="issue.worklogs" class="my-2">
        {{
          `${$tf(
            "taskPage.issueCard.loggedTime|Logolt idő:"
          )} ${secondsToJiraTime(sumLoggedSeconds(issue))}`
        }}
      </div>
      <div v-else class="my-2 has-text-grey">
        {{ $tf("taskPage.issueCard.noLoggedTime|Nincs logolás.") }}
      </div>
      <div class="my-2">
        <b-progress
          v-if="
            issue.estimateSeconds &&
            sumLoggedSeconds(issue) > issue.estimateSeconds
          "
          :max="sumLoggedSeconds(issue)"
        >
          <template #bar>
            <b-progress-bar
              :value="issue.estimateSeconds"
              type="is-primary"
            ></b-progress-bar>
            <b-progress-bar
              :value="sumLoggedSeconds(issue) - issue.estimateSeconds"
              type="is-danger"
            ></b-progress-bar>
          </template>
        </b-progress>
        <b-progress
          v-else
          :value="sumLoggedSeconds(issue)"
          :max="issue.estimateSeconds ?? 1"
          type="is-primary"
        ></b-progress>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfo from "@/components/module/info/UserInfo.vue";
import { secondsToJiraTime } from "@/utils/util";
import SelfTrackingLogModal from "@/components/timesheet/log/SelfTrackingLogModal.vue";
export default {
  name: "LoggingTaskCard",
  components: { UserInfo },
  methods: {
    secondsToJiraTime,
    sumLoggedSeconds(issue) {
      return (
        issue.worklogs?.reduce((partialSum, a) => partialSum + a.seconds, 0) ??
        0
      );
    },
    openLogModal(issue) {
      this.$buefy.modal.open({
        parent: this,
        component: SelfTrackingLogModal,
        props: {
          selectedIssue: issue,
        },
        hasModalCard: true,
        trapFocus: true,
        width: "100%",
        onCancel: async () => {
          this.$store.dispatch("enterprise_core/fetchActiveIssues");
        },
      });
    },
  },
  props: {
    issue: {
      type: Object,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.card:hover {
  cursor: pointer;
}
</style>
