<template>
  <b-autocomplete
    v-model="search"
    open-on-focus
    :data="filteredData"
    :field="field"
    :placeholder="placeholder"
    @select="
      $emit(
        'update:modelValue',
        idField && $event && !returnObject ? $event[idField] : $event
      )
    "
  />
</template>

<script>
export default {
  name: "FAutocomplete",
  inheritAttrs: false,
  props: {
    data: {
      type: Array,
      required: true,
    },
    field: {
      type: String,
      required: false,
      default: "name",
    },
    idField: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Number],
      required: false,
    },
    useTf: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const element = this.findById(this.modelValue);
    if (element) {
      this.search = element[this.field] ?? "";
    }
  },
  data() {
    return {
      search: "",
    };
  },
  watch: {
    modelValue(val) {
      const element = this.findById(val);
      if (element) {
        this.search = element[this.field] ?? "";
      }
    },
  },
  computed: {
    translatedData() {
      if (!this.useTf) return this.data;
      return this.data.map((it) => {
        it[this.field] = this.$tf(it[this.field]);
        return it;
      });
    },
    filteredData() {
      return (
        this.translatedData?.filter(
          (element) =>
            element[this.field]
              ?.toLowerCase()
              ?.indexOf(this.search?.toLowerCase()) >= 0
        ) ?? []
      );
    },
  },
  methods: {
    findById(id) {
      if (this.idField)
        return this.translatedData?.find(
          (element) => element[this.idField] === id
        );
      return this.translatedData?.find((element) => element[this.field] === id);
    },
  },
};
</script>
