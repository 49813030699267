import StoreCore from "../../StoreCore";
import axios from "axios";

const urls = {
  config: "/api/env",
};

const initState = () => {
  return {};
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit }) {
    try {
      const { data } = await axios.get(urls.config);
      commit("save", data);
    } catch (err) {
      console.error(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  save: (state, data) => {
    Object.keys(data).forEach((key) => {
      state[key] = data[key];
    });
  },
};

const getters = {
  getField: (state) => (field) => {
    return state[field];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
