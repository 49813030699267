<template>
  <section v-if="loaded && !isPageLoading">
    <div class="mb-6">
      <div class="uppercase-subtitle mt-5 mb-0">
        {{
          $tf(
            "projectResourceView.commitments.title|Erőforrás költések / Vállalások"
          )
        }}
      </div>

      <div class="is-flex is-flex-wrap-wrap has-gap-4 mt-0 mt-3 mb-4">
        <!-- Vállalások összesítve -->
        <div class="pt-1 pr-1 pl-1 has-text-centered w-16 commitment-box">
          <div class="has-text-dark-blue has-font-weight-400">
            {{ totalAllocatedMwd }} MWD / {{ totalCommitmentSum }} MWD
          </div>
          <progress
            class="progress is-small commitment-progress"
            :value="totalAllocatedMwd"
            :max="totalCommitmentSum"
          ></progress>
          <div class="has-text-weight-medium has-text-black mt-2">
            {{ $tf("projectResourceView.commitments.total|Összesen") }}
          </div>
        </div>

        <!-- Vállalások munkakörönként -->
        <div
          class="pt-1 pr-1 pl-1 has-text-centered w-16 commitment-box"
          v-for="(commitment, index) in commitmentDetails"
          :key="index"
        >
          <div class="has-text-dark-blue has-font-weight-400">
            {{ allocatedMWDByRole(commitment.roleId) }} MWD /
            {{ commitment.commitmentSum }} MWD
          </div>
          <progress
            class="progress is-small commitment-progress"
            :value="allocatedMWDByRole(commitment.roleId)"
            :max="commitment.commitmentSum"
          ></progress>
          <div class="has-text-weight-medium has-text-black mt-2">
            {{ commitment.roleName }}
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="is-flex">
        <div class="is-flex has-gap-5">
          <h3 class="heading">
            {{ $tf("projectResourceView.resource.title|Erőforrás allokáció") }}
          </h3>
          <div class="is-flex is-align-items-center mb-5">
            <b-checkbox class="mr-0" v-model="groupByRole">
              <span class="has-text-weight-light"
                >{{
                  $tf(
                    "projectResourceView.resource.checkbox.groupByRole|Csoportosítás munkakör szerint"
                  )
                }}
              </span>
            </b-checkbox>
          </div>
        </div>

        <div class="ml-4">
          <b-icon
            icon="chevron-left"
            class="cursor-pointer mr-2"
            @click="prevWeek"
          />
          <b-icon icon="undo" class="cursor-pointer mr-2" @click="resetWeek" />
          <b-icon
            icon="chevron-right"
            class="cursor-pointer"
            @click="nextWeek"
          />
        </div>
        <div class="ml-auto is-flex is-align-items-center">
          <span
            :class="
              showAllocatedTimeInHours ? 'mr-2' : 'mr-2 has-font-weight-600'
            "
          >
            {{ $tf("projectResourceView.switch.label.workday| Munkanap") }}
          </span>
          <b-switch
            type="is-info"
            passive-type="is-info"
            v-model="showAllocatedTimeInHours"
          />
          <span :class="showAllocatedTimeInHours ? 'has-font-weight-600' : ''">
            {{ $tf("projectResourceView.switch.label.workhours| Munkaóra") }}
          </span>
        </div>
      </div>

      <template v-if="groupByRole">
        <div v-for="(commitment, index) in commitmentDetails" :key="index">
          <b-collapse :model-value="false" animation="slide">
            <template #trigger="props">
              <div class="card-header is-grey mb-2" role="button">
                <div class="is-flex is-align-items-center pl-2">
                  <h3 class="heading mb-2 mt-2 ml-2 has-font-weight-600">
                    {{ commitment.roleName }}
                  </h3>
                </div>
                <div class="ml-auto mt-2 mb-2 mr-2">
                  <a>
                    <b-icon
                      :icon="props.open ? 'chevron-up' : 'chevron-down'"
                    />
                  </a>
                </div>
              </div>
            </template>

            <div class="is-flex is-flex-direction-column">
              <!--       Weeks Header -->
              <div class="row is-flex is-flex-direction-row">
                <div class="cell is-flex-2 is-flex is-align-items-flex-end">
                  <span
                    class="has-text-custom-dark-blue uppercase is-size-7 pt-5 ml-6 mb-3"
                  >
                    {{
                      $tf("projectResourceView.allocationRow.header.name| Név")
                    }}
                  </span>
                </div>
                <div
                  class="cell is-flex-1"
                  v-for="(weekData, index) of weeks"
                  :key="index"
                >
                  <div class="pt-1 pb-1">
                    <p
                      class="has-text-weight-bold is-size-5 has-text-centered mb-0"
                    >
                      {{ weekData.week }}.
                      {{
                        $tf(
                          "projectResourceView.allocationRow.header.week| hét"
                        )
                      }}
                    </p>
                    <p class="has-text-grey has-text-centered">
                      {{ weekData.startDate }} - {{ weekData.endDate }}
                    </p>
                  </div>
                </div>
              </div>

              <!--          Employees-->
              <ResourceAllocationRow
                v-for="association of filterByRole(
                  associations,
                  commitment.roleId
                )"
                :key="association.employeeId"
                :allocations="association.weekData"
                :weeks="weeks"
                :employee="employee(association.employeeId)"
                :project-id="projectData.id"
                :show-allocated-time-in-hours="showAllocatedTimeInHours"
                @cloneAllocation="saveAssociation"
                @modifyAllocation="saveAssociationDebounce"
              />
            </div>
          </b-collapse>
        </div>
      </template>
      <template v-else>
        <div class="is-flex is-flex-direction-column">
          <!--       Weeks Header -->
          <div class="row is-flex is-flex-direction-row">
            <div class="cell is-flex-2 is-flex is-align-items-flex-end">
              <span
                class="has-text-custom-dark-blue uppercase is-size-7 pt-5 ml-6 mb-3"
              >
                {{ $tf("projectResourceView.allocationRow.header.name| Név") }}
              </span>
            </div>
            <div
              class="cell is-flex-1"
              v-for="(weekData, index) of weeks"
              :key="index"
            >
              <div class="pt-1 pb-1">
                <p
                  class="has-text-weight-bold is-size-5 has-text-centered mb-0"
                >
                  {{ weekData.week }}.
                  {{
                    $tf("projectResourceView.allocationRow.header.week| hét")
                  }}
                </p>
                <p class="has-text-grey has-text-centered">
                  {{ weekData.startDate }} - {{ weekData.endDate }}
                </p>
              </div>
            </div>
          </div>

          <!--          Employees-->
          <ResourceAllocationRow
            v-for="association of associations"
            :key="association.employeeId"
            :allocations="association.weekData"
            :weeks="weeks"
            :employee="employee(association.employeeId)"
            :project-id="projectData.id"
            :show-allocated-time-in-hours="showAllocatedTimeInHours"
            @cloneAllocation="saveAssociation"
            @modifyAllocation="saveAssociationDebounce"
          />
        </div>
      </template>
      <div class="mt-4">
        <b-button
          type="is-primary"
          icon-left="plus"
          @click="allocationModalOpen = true"
        >
          {{
            $tf(
              "projectResourceView.button.newEmployee| Új alkalmazott hozzáadása"
            )
          }}
        </b-button>
      </div>

      <b-modal has-modal-card trap-focus v-model="allocationModalOpen">
        <EmployeeSelect
          :employees="employeesToSelect"
          :lead-teams="leadTeams"
          @addEmployee="handleAddEmployee"
        />
      </b-modal>
    </div>
  </section>
  <div v-else>
    <b-loading v-model="isPageLoading" :is-full-page="true" />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import LoadingMixin from "@/mixins/LoadingMixin";
import {
  addDay,
  subtractDay,
  convertHoursToMWD,
  generateNext6Weeks,
  getWeekNumber,
  isOlderThanPreviousWeek,
} from "@/utils/util";
import { useDebounceFn } from "@vueuse/core";
import EmployeeSelect from "@/components/ralloc/EmployeeSelect.vue";
import ResourceAllocationRow from "@/components/ralloc/ResourceAllocationRow.vue";
import { PROJECT_TYPES, RALLOC_PRIORITY } from "@/utils/const";

export default defineComponent({
  name: "ProjectResourceView",
  components: { ResourceAllocationRow, EmployeeSelect },
  mixins: [LoadingMixin],
  async mounted() {
    this.doStartLoading();
    this.generateWeeks();
    await this.$store.dispatch(
      "commitment/getCommitments",
      this.projectData.id
    );
    await this.setupCommitmentDetails();
    await this.$store.dispatch("employee/fetch");
    await this.$store.dispatch("census_team/fetchLeadTeams");
    await this.fetchAssociations();
    this.doFinishLoading();
    this.loaded = true;
  },
  props: {
    projectIdentifier: {
      type: String,
      required: true,
    },
    projectType: {
      type: String,
      required: true,
    },
  },
  created() {
    this.saveAssociationDebounced = useDebounceFn(
      async (...args) => await this.saveAssociation(...args),
      500
    );
  },
  data() {
    return {
      loaded: false,
      startWeekDate: new Date(),
      showAllocatedTimeInHours: false,
      weeks: [],
      commitmentDetails: [],
      allocationModalOpen: false,
      groupByRole: false,
    };
  },
  computed: {
    ...mapGetters({
      projectDataGetter: "enterprise_projects/projectData",
      supportProjectDataGetter: "support_projects/projectDetails",
      outsourceProjectDataGetter: "outsource_projects/projectDetails",
      indicators: "user_indicator/indicators",
      commitments: "commitment/commitments",
      employees: "employee/employees",
      leadTeams: "census_team/leadTeams",
      associations: "association/associations",
    }),
    employeesToSelect() {
      return this.employees.filter(
        (emp) => !this.associations.some((asc) => asc.employeeId === emp.id)
      );
    },
    projectData() {
      if (this.projectType === PROJECT_TYPES.BASIC) {
        const data = this.projectDataGetter?.(this.projectIdentifier);
        return data || {};
      } else if (this.projectType === PROJECT_TYPES.SUPPORT) {
        const data = this.supportProjectDataGetter?.(this.projectIdentifier);
        return data || {};
      } else if (this.projectType === PROJECT_TYPES.OUTSOURCE) {
        const data = this.outsourceProjectDataGetter?.(this.projectIdentifier);
        return data || {};
      }

      return {};
    },
    totalCommitmentSum() {
      return this.commitmentDetails.reduce(
        (sum, role) => sum + role.commitmentSum,
        0
      );
    },
    totalAllocatedMwd() {
      return this.convertHoursToMWD(
        this.associations
          .flatMap((asc) => asc.weekData)
          .reduce((sum, weekData) => sum + (weekData.allocatedHours || 0), 0)
      );
    },
  },
  watch: {
    startWeekDate() {
      this.generateWeeks();
    },
  },
  methods: {
    convertHoursToMWD,
    addDay,
    subtractDay,
    isOlderThanPreviousWeek,
    async saveAssociationDebounce(request) {
      await this.saveAssociationDebounced(request);
    },
    async saveAssociation(args) {
      const request = {
        ...args,
        projectId: this.projectData.id,
        type: this.projectType,
      };

      if (this.isOlderThanPreviousWeek(request.year, request.week)) {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$tf(
            "projectResourceView.saveAssociation.toast.pastAllocationForbidden|Egy hétnél korábbi allokáció nem megengedett!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }

      await this.$store.dispatch("association/createAssociation", request);
      await this.fetchAssociations();
    },
    async fetchAssociations() {
      await this.$store.dispatch("association/getAssociationsForProject", {
        projectId: this.projectData?.id,
        from: this.projectData?.startDate,
        to: this.projectData?.dueDate,
      });
    },
    async setupCommitmentDetails() {
      if (!this.commitments || this.commitments.length === 0) {
        this.commitmentDetails = [];
        return;
      }

      const roleMap = new Map();

      this.commitments[0]?.commitments.forEach((commitment) => {
        const getIndicatorById = (id) =>
          this.indicators.find((indicator) => indicator.id === id);

        const roleId = commitment.roleId;
        const roleName = getIndicatorById(roleId)?.name;

        if (!roleMap.has(roleId)) {
          roleMap.set(roleId, {
            roleName,
            roleId,
            commitmentSum: 0,
            commitments: [],
          });
        }

        const role = roleMap.get(roleId);

        role.commitments.push({
          seniorityId: commitment.seniorityId,
          workDays: commitment.workDays,
          seniority: getIndicatorById(commitment.seniorityId),
        });

        role.commitmentSum += commitment.workDays;
      });

      this.commitmentDetails = Array.from(roleMap.values());
    },
    allocatedMWDByRole(roleId) {
      const employeeRoles = new Map(
        this.employees.map((emp) => [emp.id, emp.roleId])
      );

      return this.convertHoursToMWD(
        this.associations
          .filter((asc) => employeeRoles.get(asc.employeeId) === roleId)
          .flatMap((asc) => asc.weekData)
          .reduce((sum, weekData) => sum + (weekData.allocatedHours || 0), 0)
      );
    },
    async handleAddEmployee(employeeId) {
      const today = new Date();
      await this.saveAssociation({
        employeeId: employeeId,
        year: today.getFullYear(),
        week: getWeekNumber(today),
        hours: 8,
        priority: RALLOC_PRIORITY.MEDIUM.value,
      });

      this.allocationModalOpen = false;
    },
    nextWeek() {
      this.startWeekDate = new Date(this.addDay(this.startWeekDate, 7));
    },
    prevWeek() {
      this.startWeekDate = new Date(this.subtractDay(this.startWeekDate, 7));
    },
    resetWeek() {
      this.startWeekDate = new Date();
    },
    generateWeeks() {
      this.weeks = generateNext6Weeks(this.startWeekDate);
    },
    employee(employeeId) {
      return this.employees.find((emp) => emp.id === employeeId);
    },
    filterByRole(associations, roleId) {
      const employeeMap = this.employees.reduce((map, emp) => {
        map[emp.id] = emp;
        return map;
      }, {});

      return associations.filter((asc) => {
        const employee = employeeMap[asc.employeeId];
        return employee && employee.roleId === roleId;
      });
    },
    getWeekNumber,
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors";

.commitment-box {
  border: 1px solid black;
  border-radius: 5px;
}

.commitment-progress {
  height: 0.6rem !important;
  background-color: #eee;

  &::-webkit-progress-bar {
    background-color: #eee;
  }

  &::-webkit-progress-value {
    background-color: var(--custom-dark-blue);
  }

  &::-moz-progress-bar {
    background-color: var(--custom-dark-blue);
  }
}

.is-grey {
  background: #e8e8e8;
}
</style>
