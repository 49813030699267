<template>
  <form>
    <h3 class="heading">{{ $tf("userForm.basics.title|Alapadatok") }}</h3>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('userForm.identifier|Azonosító')"
          label-position="on-border"
          expanded
        >
          <b-input v-model="userId" disabled></b-input>
        </b-field>
      </div>

      <div class="column">
        <vuelidated-input
          v-model="userEdit.name"
          :placeholder="$tf('userForm.name.placeholder|Munkatárs neve')"
          :label="$tf('userForm.name|Név')"
          :ref-value="'name'"
          :validation-rule="v$.userEdit.name"
          label-position="on-border"
          expanded
        />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <vuelidated-input
          v-model="userEdit.username"
          :placeholder="
            $tf('userForm.username.placeholder|Munkatárs felhasználóneve')
          "
          :label="$tf('userForm.username|Felhasználónév')"
          :validation-rule="v$.userEdit.username"
          :disabled="!newUser"
          label-position="on-border"
          expanded
        />
      </div>
      <div class="column">
        <vuelidated-input
          v-model="userEdit.email"
          :placeholder="$tf('userForm.email.placeholder|Munkatárs e-mail címe')"
          :label="$tf('userForm.email|E-mail cím')"
          :validation-rule="v$.userEdit.email"
          label-position="on-border"
          expanded
        />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <phone-input
          :label="$tf('userForm.phone|Telefonszám')"
          :placeholder="
            $tf('userForm.phone.placeholder|Munkatárs telefonszáma')
          "
          v-model="userEdit.phone"
          ref="phoneInput"
          class="phone-input"
          required
        />
      </div>

      <div class="column">
        <div class="switch-column is-flex has-gap-5 is-align-items-start">
          <b-switch
            v-model="userEdit.locked"
            :rounded="false"
            type="is-danger"
            class="field"
          >
            <label>{{ $tf("userForm.locked|Zárolt") }}</label>
          </b-switch>

          <b-switch
            v-model="userEdit.hidden"
            :rounded="false"
            type="is-danger"
            class="field"
          >
            <label>{{ $tf("userForm.hidden|Rejtett") }}</label>
          </b-switch>
        </div>
      </div>
    </div>

    <div class="columns" v-if="!newUser">
      <div class="column">
        <b-field>
          <b-button @click="resetPassword" type="is-info">
            {{ $tf("userForm.resetPassword|Jelszó helyreállítása") }}
          </b-button>
        </b-field>
      </div>

      <div class="column"></div>
    </div>

    <h3 class="heading">
      {{ $tf("userForm.companySettings.title|Céges beállítások") }}
    </h3>

    <div
      class="columns"
      v-if="issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum"
    >
      <div v-if="newUser" class="column is-full">
        <b-checkbox v-model="createsJiraUser">{{
          $tf("userForm.createJiraUser|Jira felhasználó létrehozása")
        }}</b-checkbox>
      </div>
      <div v-else class="column is-one-quarter">
        <b-button
          v-if="!userEdit.externalId"
          type="is-info"
          :loading="createsJiraUser"
          @click="
            userData.email !== userEdit.email
              ? openJiraConfirm()
              : createJiraUser()
          "
          >{{
            createsJiraUser
              ? ""
              : $tf("userForm.createJiraUser|Jira felhasználó létrehozása")
          }}</b-button
        >
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('userForm.startDate|Belépés dátuma')"
          label-position="on-border"
          expanded
        >
          <b-datepicker
            v-model="userEdit.startDate"
            position="is-bottom-right"
            :placeholder="$tf('userForm.startDate.placeholder|Belépés dátuma')"
            icon="calendar-week"
            trap-focus
            editable
          />
        </b-field>
      </div>
      <div class="column">
        <b-field
          :label="$tf('userForm.endDate|Kilépés dátuma')"
          label-position="on-border"
          expanded
        >
          <b-datepicker
            v-model="userEdit.endDate"
            position="is-bottom-right"
            :placeholder="$tf('userForm.endDate.placeholder|Kilépés dátuma')"
            icon="calendar-week"
            trap-focus
            editable
          />
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div
        class="column is-one-quarter"
        v-if="issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum"
      >
        <vuelidated-input
          :disabled="!userEdit.loggingRequired"
          v-model="userEdit.externalId"
          :placeholder="$tf('userForm.jiraId.placeholder|Jira azonosító')"
          :label="$tf('userForm.jiraId|Jira ID')"
          ref-value="jiraId"
          :validation-rule="v$.userEdit.externalId"
          icon="fingerprint"
          label-position="on-border"
        />
      </div>
      <div class="column">
        <div class="checkbox-column">
          <b-field>
            <b-checkbox v-model="userEdit.loggingRequired">
              {{ $tf("userForm.loggingRequired|Timesheetelésben részt vesz") }}
            </b-checkbox>
          </b-field>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('userForm.parentCompany|Anyacég')"
          label-position="on-border"
        >
          <b-input
            v-model="userEdit.parentCompany"
            :disabled="!userEdit.external"
            :placeholder="$tf('userForm.parentCompany.placeholder|Anyacég')"
          />
        </b-field>
      </div>
      <div class="column">
        <div class="checkbox-column">
          <b-field>
            <b-checkbox v-model="userEdit.external"
              >{{ $tf("userForm.external|Külsős") }}
            </b-checkbox>
          </b-field>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column mt-1">
        <b-field
          :label="$tf('userForm.workSchedule|Munkarend')"
          label-position="on-border"
        >
          <b-tabs
            v-model="userEdit.workSchedule"
            type="is-toggle"
            class="toggle-only"
          >
            <b-tab-item
              :label="$tf('userForm.workSchedule.none|Nincs')"
              value="NONE"
            />
            <b-tab-item
              :label="$tf('userForm.workSchedule.assigned|Központi')"
              value="ASSIGNED"
            />
          </b-tabs>
        </b-field>
      </div>
      <div class="column">
        <b-field
          v-if="newUser && userEdit.workSchedule === 'ASSIGNED'"
          class="mt-2"
        >
          <b-checkbox v-model="userEdit.addDefaultWorkschedule">
            {{
              $tf(
                "userForm.workSchedule.assigned.addDefault|Kezdeti munkarend felvétele"
              )
            }}
          </b-checkbox>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('userForm.rank|Rangsor')"
          label-position="on-border"
        >
          <b-input
            v-model="userEdit.rank"
            :placeholder="$tf('userForm.rank.placeholder|1234')"
            type="number"
          />
        </b-field>
      </div>
      <div class="column"></div>
    </div>

    <h3 class="heading">
      {{ $tf("userForm.competencies.title|Kompetenciák") }}
    </h3>

    <b-field :label="$tf('userForm.title|Titulus')" label-position="on-border">
      <b-input
        v-model="userEdit.title"
        :placeholder="
          $tf('userForm.parentCompany.placeholder|Megjelenített titulus')
        "
      />
    </b-field>
    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('userForm.role|Szerepkör')"
          label-position="on-border"
        >
          <b-select
            v-model="userEdit.roleId"
            :placeholder="$tf('userForm.title.placeholder|Kérlek, válassz...')"
            expanded
            :class="{ 'select-placeholder': userEdit.roleId == null }"
          >
            <option></option>
            <option
              v-for="indicator in indicators.filter(
                (i) => i.type === USER_INDICATOR_TYPE_ENUM.ROLE
              )"
              :key="indicator.id"
              :value="indicator.id"
            >
              {{ indicator.name }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div class="column">
        <b-field
          :label="$tf('userForm.seniority|Szenioritás')"
          label-position="on-border"
        >
          <b-select
            v-model="userEdit.seniorityId"
            :placeholder="
              $tf('userForm.seniority.placeholder|Kérlek, válassz...')
            "
            expanded
            :class="{
              'select-placeholder': userEdit.seniorityId == null,
            }"
          >
            <option></option>
            <option
              v-for="indicator in indicators.filter(
                (i) => i.type === USER_INDICATOR_TYPE_ENUM.SENIORITY
              )"
              :key="indicator.id"
              :value="indicator.id"
            >
              {{ indicator.name }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div class="column">
        <b-field
          :label="$tf('userForm.department|Terület')"
          label-position="on-border"
        >
          <b-select
            v-model="userEdit.departmentId"
            :placeholder="
              $tf('userForm.department.placeholder|Kérlek, válassz...')
            "
            expanded
            :class="{
              'select-placeholder': userEdit.departmentId == null,
            }"
          >
            <option></option>
            <option
              v-for="indicator in indicators.filter(
                (i) => i.type === USER_INDICATOR_TYPE_ENUM.DEPARTMENT
              )"
              :key="indicator.id"
              :value="indicator.id"
            >
              {{ indicator.name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('userForm.competencies|Kompetenciák')"
          expanded
          label-position="on-border"
        >
          <b-taginput
            v-model="userEdit.competenciesEdit"
            :data="filteredCompetencies"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            icon="book"
            field="text"
            :placeholder="
              $tf('userForm.competencies.placeholder|Kompetenciák választása')
            "
            @typing="filterTags($event, competencies, 'filteredCompetencies')"
          >
          </b-taginput>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('userForm.roles|Jogosultságok')"
          expanded
          label-position="on-border"
        >
          <b-taginput
            v-model="userEdit.rolesEdit"
            :data="filteredRoles"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            icon="microchip"
            field="text"
            :placeholder="$tf('userForm.roles.placeholder|Jogkörök választása')"
            @typing="filterTags($event, roles, 'filteredRoles')"
          >
          </b-taginput>
        </b-field>
      </div>
    </div>

    <h3 class="heading">{{ $tf("userForm.misc.title|Egyéb") }}</h3>

    <div class="columns">
      <div class="column">
        <phone-input
          :label="$tf('userForm.contact|Telefonszám')"
          :placeholder="$tf('userForm.contact.placeholder|Telefonszám')"
          ref="contactInput"
          v-model="userEdit.contact"
        />
      </div>

      <div class="column">
        <b-field
          :label="$tf('userForm.birthMonth|Születési hónap')"
          label-position="on-border"
        >
          <b-datepicker
            v-model="userEdit.birthDate"
            position="is-bottom-right"
            :placeholder="
              $tf('userForm.birthMonth.placegholder|Születési hónap')
            "
            icon="calendar-week"
            type="month"
            :date-formatter="returnMonth()"
            trap-focus
          >
            <template #header>
              <span> </span>
            </template>
          </b-datepicker>
        </b-field>
      </div>
      <div class="column">
        <b-field
          :label="$tf('userForm.birthDay|Születési nap')"
          label-position="on-border"
        >
          <b-datepicker
            v-model="userEdit.birthDate"
            position="is-bottom-right"
            :placeholder="$tf('userForm.birthDay.placeholder|Születési nap')"
            icon="calendar-week"
            :date-formatter="returnDay()"
            :nearby-month-days="false"
            trap-focus
          >
            <template #header>
              <span> </span>
            </template>
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('userForm.dataLink|Adatbekérő')"
          label-position="on-border"
        >
          <b-input
            :placeholder="$tf('userForm.dataLink.placeholder|Adatbekérő URL')"
            icon="link"
            type="url"
            v-model="userEdit.dataLink"
          >
          </b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('userForm.flair|Bio')"
          expanded
          label-position="on-border"
        >
          <b-input
            v-model="userEdit.flair"
            :placeholder="
              $tf(
                'userForm.flair.placeholder|Egyedi megjegyzés / idézet / ars poetica'
              )
            "
            icon="quote-left"
          >
          </b-input>
        </b-field>
      </div>
    </div>
    <b-field class="file" :class="{ 'has-name': !!userEdit.avatar }">
      <b-upload
        v-model="userEdit.avatar"
        class="file-label"
        accept="image/*"
        rounded
      >
        <span class="file-cta">
          <b-icon class="file-icon" icon="upload"></b-icon>
          <span class="file-label">{{
            $tf("userForm.avatarUpload|Profilkép feltöltése")
          }}</span>
        </span>
        <span class="file-name" v-if="userEdit.avatar">
          {{ userEdit.avatar.name }}
        </span>
      </b-upload>
    </b-field>
  </form>
</template>

<script>
import { required, maxLength, email, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { getCurrentInstance } from "vue";
import { mapGetters } from "vuex";
import {
  USER_INDICATOR_TYPE_ENUM,
  USER_PERMISSION_TYPE_ENUM,
  ROLES,
  ISSUE_TRACKING_SYSTEM,
} from "@/utils/const";
import { deepCopy, normalize } from "@/utils/util";
import PhoneInput from "../../module/input/PhoneInput";

export default {
  name: "UserForm",
  components: { VuelidatedInput, PhoneInput },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    newUser: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ISSUE_TRACKING_SYSTEM() {
      return ISSUE_TRACKING_SYSTEM;
    },
    ...mapGetters({
      indicators: "user_indicator/indicators",
      employees: "employee/employees",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
    userId() {
      if (this.newUser) {
        return "{Generated}";
      } else {
        return this.userEdit.id;
      }
    },
  },
  async mounted() {
    this.competencies = this.indicators
      .filter((i) => i.type === USER_INDICATOR_TYPE_ENUM.COMPETENCY)
      .map((i) => {
        return {
          value: i.id,
          text: i.name,
        };
      });
    this.roles = Object.values(ROLES).map((r) => {
      return {
        value: r.VALUE,
        text: r.TEXT,
      };
    });
    this.filteredRoles = this.roles;
    this.filteredCompetencies = this.competencies;
    if (!this.newUser) {
      this.userEdit = deepCopy(this.userData);
      if (this.userEdit.roles) {
        this.userEdit.rolesEdit = this.userEdit.roles
          .split(",")
          .map((role) => this.roles.filter((rr) => rr.value === role).shift());
        this.userEdit.avatar = null;
      }

      if (this.userEdit.competencies) {
        this.userEdit.competenciesEdit = this.userEdit.competencies.map(
          (competency) =>
            this.competencies.filter((cc) => cc.value === competency.id).shift()
        );
      }

      if (this.userEdit.startDate) {
        this.userEdit.startDate = new Date(this.userEdit.startDate);
      }

      if (this.userEdit.endDate) {
        this.userEdit.endDate = new Date(this.userEdit.endDate);
      }

      if (this.userEdit.birthDate) {
        this.userEdit.birthDate = new Date(this.userEdit.birthDate);
      }
    }
  },
  data() {
    return {
      normalize,
      USER_INDICATOR_TYPE_ENUM,
      USER_PERMISSION_TYPE_ENUM,
      ROLES,
      competencies: [],
      createsJiraUser: false,
      filteredRoles: [],
      filteredCompetencies: [],
      roles: [],
      userEdit: {
        avatar: null,
        name: null,
        dataLink: null,
        department: null,
        occupation: null,
        username: "",
        email: null,
        flair: null,
        id: null,
        phone: null,
        contact: null,
        external: false,
        portraitLink: null,
        locked: false,
        hidden: false,
        rank: null,
        roles: null,
        rolesEdit: [],
        competencies: null,
        competenciesEdit: [],
        parentCompany: null,
        loggingRequired: null,
        startDate: null,
        endDate: null,
        birthDate: null,
        aggregateHours: null,
        tier: null,
        addDefaultWorkschedule: false,
        workSchedule: "NONE",
      },
      changes: [],
    };
  },
  validations: {
    userEdit: {
      id: {
        maxLength: maxLength(255),
      },
      externalId: {
        maxLength: maxLength(255),
      },
      name: {
        required,
        maxLength: maxLength(255),
      },
      username: {
        required,
        maxLength: maxLength(255),
      },
      email: {
        required,
        maxLength: maxLength(255),
        email,
      },
      // aggregateHours: {
      //   required: requiredIf((changeEntry) => {
      //     console.log(changeEntry);
      //     return changeEntry.workSchedule === "AGGREGATE";
      //   }),
      // },
      /*birthDate: {
        dateFormat(value) {
          return (
            !value ||
            !!value.match(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/)
          );
        },
      },*/
    },
  },
  methods: {
    filterTags(value, selectable, fieldKey) {
      let filtered = [];
      if (!value) {
        filtered = selectable;
      } else {
        filtered = selectable.filter((e) =>
          normalize(e.text).includes(normalize(value))
        );
      }
      this[fieldKey] = filtered;
    },
    async close() {
      await this.$store.dispatch("employee/fetch");
      this.cancel();
    },
    cancel() {
      this.instance.emit("close");
    },
    returnMonth() {
      return (date) =>
        new Intl.DateTimeFormat("default", { month: "long" }).format(date);
    },
    returnDay() {
      return (date) =>
        new Intl.DateTimeFormat("default", { day: "numeric" }).format(date);
    },
    resetPassword() {
      this.$buefy.dialog.confirm({
        message: this.$tf(
          "userForm.passwordResetModal.message|Biztos helyre szeretnéd állítani {name} jelszavát?",
          { name: this.userEdit.name }
        ),
        onConfirm: () =>
          this.$store.dispatch(
            "census_user/resetAdminPassword",
            this.userEdit.id
          ),
      });
    },
    openJiraConfirm() {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "userForm.jiraConfirmModal.title|Jira fiók létrehozása"
        ),
        message: this.$tf(
          "userForm.jiraConfirmModal.message|A felhasználónak megváltozattad az email címét, de ezt még nem mentetted el. A Jira fiók az új {email} címmel fog létrejönni!",
          { email: this.userEdit.email }
        ),
        confirmText: this.$tf("userForm.jiraConfirmModal.confirm|Rendben"),
        cancelText: this.$tf("userForm.jiraConfirmModal.cancel|Mégsem"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => this.createJiraUser(),
      });
    },
    async createJiraUser() {
      this.createsJiraUser = true;
      let req = deepCopy(this.userData);
      req.email = this.userEdit.email;
      await this.$store.dispatch("employee/createJiraUser", req);
      this.userEdit.externalId = this.employees.find(
        (e) => e.id === this.userData.id
      ).externalId;
      this.createsJiraUser = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.animation-content {
  .scrollable-modal-card {
    h3 {
      &:not(:first-child) {
        margin-top: 1rem;
      }

      margin-bottom: 1rem;
    }
  }
}
</style>
<style lang="scss">
@import "~@/assets/scss/colors.scss";

.is-have {
  .taginput-container {
    border: 1px solid $red;

    .tag:not(body) {
      background-color: $green;
    }
  }
}

.is-should-have {
  .taginput-container {
    border: 1px solid red;

    .tag:not(body) {
      background-color: $yellow;
    }
  }
}

.is-should-not-have {
  .taginput-container {
    border: 1px solid $red;

    .tag:not(body) {
      background-color: $red;
      color: $white;
    }
  }
}

.is-highlighted {
  border-radius: 8px;
  box-shadow: 0 0 5px 2px $purple;
}
</style>
