<template>
  <div class="container">
    <h1 v-if="userId" class="title">
      {{ $tf("workSchedule.self.title|Munkarendem") }}
    </h1>
    <h2 class="subtitle">
      {{
        $tf(
          "workSchedule.self.subtitle|Heti munkarend és beosztás megtekintése"
        )
      }}
    </h2>
    <div class="content" v-if="ownWorkSchedules">
      <div class="weekComponent">
        <b-field grouped class="week-select">
          <b-button
            icon-left="chevron-right"
            class="left-button"
            @click="prev()"
            title="Hátra"
          />
          <b-input
            expanded
            :value="
              formatDate(mondayOfActDate) + ' - ' + formatDate(sundayOfActDate)
            "
            disabled
          />
          <b-button
            icon-left="chevron-right"
            class="right-button"
            @click="next()"
            title="Előre"
          />
        </b-field>
      </div>
    </div>
    <time-sheet-summary
      class="mt-4"
      :from="formatDate(mondayOfActDate)"
      :to="formatDate(sundayOfActDate)"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { formatDate } from "@/utils/util";
import { DAY_NUMBER_LABEL } from "@/utils/const";
import TimeSheetSummary from "@/components/timesheet/TimeSheetSummary.vue";

export default {
  name: "MyWorkSchedule",
  components: {
    TimeSheetSummary,
  },
  data() {
    return {
      DAY_NUMBER_LABEL,
      actDate: new Date(),
      editMode: false,
    };
  },
  computed: {
    mondayOfActDate() {
      switch (this.actDate.getDay) {
        case 0:
          return new Date(
            new Date(this.actDate).setDate(this.actDate.getDate() - 6)
          );
        case 1:
          return this.actDate;
        default:
          return new Date(
            new Date(this.actDate).setDate(
              this.actDate.getDate() - this.actDate.getDay() + 1
            )
          );
      }
    },
    sundayOfActDate() {
      return this.actDate.getDay() === 0
        ? new Date(new Date(this.actDate).setDate(this.actDate.getDate() + 7))
        : new Date(
            new Date(this.actDate).setDate(
              this.actDate.getDate() - this.actDate.getDay() + 7
            )
          );
    },
    ...mapGetters({
      userId: "session/userId",
      ownWorkSchedules: "work_schedule/ownWorkSchedules",
      associations: "association/associations",
    }),
  },
  methods: {
    formatDate,
    async getWeeklyData() {
      this.doStartLoading();
      let range = {
        from: formatDate(this.mondayOfActDate),
        to: formatDate(this.sundayOfActDate),
      };
      await this.$store.dispatch("association/getMine", range);
      await this.$store.dispatch("worklog/fetchMyWeek", range);
      await this.$store.dispatch("worklog/fetchMyExpectedWeek", range);
      await this.$store.dispatch("worklog/fetchMyLoggingRequired", range);
      this.doFinishLoading();
    },
    prev() {
      this.actDate = new Date(this.actDate.setDate(this.actDate.getDate() - 7));
      this.getWeeklyData();
    },
    next() {
      this.actDate = new Date(this.actDate.setDate(this.actDate.getDate() + 7));
      this.getWeeklyData();
    },
    ...mapMutations({
      doStartLoading: "loader/doStartLoadPage",
      doFinishLoading: "loader/doFinishLoadPage",
    }),
  },
  async mounted() {
    await this.doStartLoading();
    this.$store.commit("work_schedule/setOwnWorkSchedules", null);
    await this.$store.dispatch("work_schedule/getWorkSchedulesForMe", true);
    this.$store.dispatch("work_schedule/getWorkScheduleAdminSites");
    await this.getWeeklyData();
    await this.doFinishLoading();
  },
};
</script>

<style scoped></style>
