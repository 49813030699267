<template>
  <section class="section">
    <div class="container">
      <h1 class="title mb-0">
        {{ $tf("utilizationPage.title|Utilizáció & Konfliktusok") }}
      </h1>
      <div class="w-40">
        <b-tabs
          v-model="activeTab"
          type="is-toggle"
          class="toggle-only"
          expanded
        >
          <b-tab-item :value="TABS.TEAMS">
            <template #header>
              <span class="tab-item">
                {{ $tf("utilizationPage.tab.teams|Csapatok") }}
              </span>
            </template>
          </b-tab-item>
          <b-tab-item :value="TABS.CONFLICTS">
            <template #header>
              <span class="tab-item">
                {{ $tf("utilizationPage.tab.conflicts|Konfliktusok") }}
              </span>
            </template>
          </b-tab-item>
        </b-tabs>
      </div>

      <div class="mt-6">
        <template v-if="activeTab === TABS.TEAMS">
          <UtilizationTeams />
        </template>
        <template v-else-if="activeTab === TABS.CONFLICTS">
          <UtilizationConflict />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import UtilizationTeams from "@/components/ralloc/UtilizationTeams.vue";
import UtilizationConflict from "@/components/ralloc/UtilizationConflict.vue";

const PREDEFINED_TABS = {
  TEAMS: "TEAMS",
  CONFLICTS: "CONFLICTS",
};

export default defineComponent({
  name: "UtilizationView",
  components: { UtilizationConflict, UtilizationTeams },
  data() {
    return {
      TABS: PREDEFINED_TABS,
      activeTab: PREDEFINED_TABS.TEAMS,
    };
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.tab-item {
  font-weight: 800;
  color: $custom-dark-blue;
}
</style>
